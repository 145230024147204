<template>
  <SAuthForm
    :isAuthPage="isAuthPage"
    :isDisplayedImageAndTitle="isDisplayedImageAndTitle"
    :lang="this.$route.params.lang"
    :location="getLocation"
    termsAndConditionsPageName="terms-and-conditions"
    privacyPolicyPageName="https://www.slg.lu/en/politique-de-confidentialite/"
    @setGlobalLoading="setGlobalLoading"
    @setAuthToken="setAuthToken"
    @populateUserProfileInfo="populateUserProfileInfo"
    @redirect="redirect"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { SAuthForm, SGlobalErrorDialogMixin } from '@slg/web-customer-shared';

export default {
  name: 'AuthForm',
  components: {
    SAuthForm,
  },
  mixins: [SGlobalErrorDialogMixin],
  props: {
    isAuthPage: {
      type: Boolean,
      default: true,
    },
    isDisplayedImageAndTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getLocation: 'auth/getLocation',
    }),
  },
  methods: {
    setGlobalLoading(isLoading) {
      this.$store.commit('common/setGlobalLoading', isLoading);
    },
    setAuthToken(authToken) {
      if (localStorage.getItem('authToken') !== authToken) {
        this.$store.commit('auth/clearAuthTokenAndProfileInfo');
        this.$store.commit('auth/setAuthToken', authToken);
      }
    },
    async populateUserProfileInfo() {
      try {
        await this.$store.dispatch('auth/populateUserProfileInfo');
      } catch (e) {
        await this.setGlobalError(e);
      }
    },
    async redirect() {
      await this.$emit('onSignInSuccess');
    },
  },
}
</script>
